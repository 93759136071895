/* =========================================================================
 * TYPOGRAPHY UTILITIES
 * ========================================================================= */

._ffbase {
	font-family: $baseFontFamily !important;
}

._ffheading {
	font-family: $headingsFontFamily !important;
}

._ffmono {
	font-family: $monospaceFontFamily !important;
}

._hover\:tdu:hover {
	text-decoration: underline !important;
}

._wbba {
	word-break: break-all;
}
