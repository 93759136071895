/* ==============================================================================================
 * HIGHLIGHTS LIST COMPONENT
 * ============================================================================================== */

.c-highlightsList {
	display: flex;
	flex-flow: row wrap;
}

.c-highlightsList__itemContainer {
	display: flex;
	flex-direction: column;
	position: relative;
	height: 100%;
	padding-left: $globalSpacingSm;
}

.c-highlightsList__itemContainer:before {
	content: "";
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	width: 5px;
	background: getColor("red");
}
