/* ==============================================================================================
 * BORDER UTILITIES
 * ============================================================================================== */

$borderWidths: 1 2 3 4 !default;
$borderColors: $colors !default;
$borderRadii: 2 3 50% !default;

$borderStyles: (
	"n": none,
	"h": hidden,
	"dt": dotted,
	"ds": dashed,
	"s": solid,
	"db": double,
	"dtds": dot-dash,
	"dtdtds": dot-dot-dash,
	"w": wave,
	"g": groove,
	"r": ridge,
	"i": inset,
	"o": outset,
) !default;

$borderPositions: (
	null: "",
	"t": "top",
	"r": "right",
	"b": "bottom",
	"l": "left",
) !default;

@mixin borderUtility($breakpoint: null) {

	@each $positionName, $propertySuffix in $borderPositions {
		@if $positionName != null {
			$propertySuffix: "-" + $propertySuffix
		}



		/* Zero border
		 * --------------------------- */

		._bd#{$positionName}0 {
			border#{$propertySuffix}: 0;
		}



		/* Shorthand
		 * --------------------------- */

		@each $width in $borderWidths {
			@each $colorName, $colorHex in $borderColors {
				._bd#{$positionName}#{$width}#{$colorName}#{$breakpoint} {
					border#{$propertySuffix}: ($width * 1px) $colorHex solid !important;
				}
			}
		}



		/* Width
		 * --------------------------- */

		@each $width in $borderWidths {
			._bd#{$positionName}w#{$width}#{$breakpoint} {
				border#{$propertySuffix}-width: ($width * 1px) !important;
			}
		}



		/* Border Styles
		 * --------------------------- */

		@each $styleKey, $styleType in $borderStyles {
			._bd#{$positionName}s#{$styleKey}#{$breakpoint} {
				border#{$propertySuffix}-style: $styleType;
			}
		}



		/* Color
		 * --------------------------- */

		@each $colorName, $colorHex in $borderColors {
			._bd#{$positionName}c#{$colorName}#{$breakpoint} {
				border#{$propertySuffix}-color: $colorHex !important;
			}
		}
	}



	/* Radius
	 * --------------------------- */

	@each $radius in $borderRadii {
		$radiusName: $radius;

		@if (unit($radius) == "%") {
			// strip the unit with some wizardry as sass won't implement anything like that
			$unitlessRadius: $radius / ($radius * 0 + 1);
			$radiusName: "#{$unitlessRadius}p";
		}
		@else {
			$radius: $radius * 1px;
		}

		._bdrs#{$radiusName}#{$breakpoint} {
			border-radius: $radius !important;
		}
	}
}

@include borderUtility();
