/* ==============================================================================================
 * TEXT COLOR UTILITIES
 * ============================================================================================== */


@mixin textColorUtility($breakpoint: null) {
	._base#{$breakpoint} {
		color: $baseColor !important;
	}

	@each $colorName, $colorHex in $colors {
		._#{$colorName}#{$breakpoint} {
			color: $colorHex !important;
		}

		._hover\:#{$colorName}#{$breakpoint}:hover {
			color: $colorHex !important;
		}
	}
}

@include textColorUtility();
