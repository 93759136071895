/* ==============================================================================================
 * HERO VIDEO
 * ============================================================================================== */

.c-heroVideo__placeholder {
	transition: .5s ease-in-out;
	opacity: 1;
	cursor: pointer;
}

.c-heroVideo.is-playing .c-heroVideo__placeholder {
	opacity: 0;
	visibility: hidden;
}

.c-heroVideo__placeholderImage {
	max-height: 100%;
	min-width: 100%;
	object-fit: cover;
	height: 100%;
}

.c-heroVideo__playButtonIcon {
	border-radius: 50%;
	box-shadow: 0 0 0 0 getColor("red");
	transform: scale(1);
}

.c-heroVideo.is-loading .c-heroVideo__playButtonIcon {
	animation: videoPlayButtonPulse 2s infinite;
}

.c-heroVideo__playButtonIcon {
	font-size: getFontSize(64);

	@include mq($from: "laptop") {
		font-size: 156px;
	}
}

@keyframes videoPlayButtonPulse {
	0% {
		transform: scale(1);
		box-shadow: 0 0 0 0 rgba(getColor("red"), 0.5);
	}

	30% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(getColor("red"), 0.5);
	}

	100% {
		transform: scale(1);
		box-shadow: 0 0 2px 10px rgba(getColor("red"), 0);
	}
}
