/* =========================================================================
 * COLOR SETTINGS
 * ========================================================================= */

$colors: (
	"black": #000000,
	"white": #FFFFFF,

	"red": #0070c9,
	"darkRed": #AB0021,

	"lightOrange": #FBBD9C,
	"darkOrange": #712300,

	"gray90": #1A1A1A,
	"gray80": #262626,
	"gray60": #666666,
	"gray40": #A7A9AC,
	"gray20": #D1D3D4,
	"gray10": #F7F7F7,

	"lightGray": #E8EAE8,
);

@function getColor($key) {
	@return map_get($colors, $key);
}
