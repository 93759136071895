/* ==============================================================================================
 * APP FONTS
 * ============================================================================================== */

@font-face {
	font-family: "Akkurat Pro";
	src: url("/fonts/AkkuratPro-Light.woff2") format("woff2"),
		url("/fonts/AkkuratPro-Light.woff") format("woff"),
		url("/fonts/AkkuratPro-Light.otf") format("opentype");
	font-weight: 300;
}

@font-face {
	font-family: "Akkurat Pro";
	src: url("/fonts/AkkuratPro-Regular.woff2") format("woff2"),
		url("/fonts/AkkuratPro-Regular.woff") format("woff"),
		url("/fonts/AkkuratPro-Regular.otf") format("opentype");
	font-weight: 400;
}

@font-face {
	font-family: "Akkurat Pro";
	src: url("/fonts/AkkuratPro-Bold.woff2") format("woff2"),
		url("/fonts/AkkuratPro-Bold.woff") format("woff"),
		url("/fonts/AkkuratPro-Bold.otf") format("opentype");
	font-weight: 500;
}

@font-face {
	font-family: "Akkurat Mono";
	src: url("/fonts/akkurat-mono.woff2") format("woff2"),
		url("/fonts/akkurat-mono.woff") format("woff"),
		url("/fonts/akkurat-mono.otf") format("opentype");
	font-style: normal;
	font-weight: 400;
}
