/* ==============================================================================================
 * FADE SCROLLABLE
 * ============================================================================================== */

/*! purgecss start ignore */
.c-fadeScrollable {
	position: relative;

	&::before,
	&::after {
		content: "";
		opacity: 0;
		position: absolute;
		pointer-events: none;
		top: 0;
		bottom: 0;
		width: 32px;
		z-index: 15;
		transition: opacity 200ms ease;
	}

	&::before {
		left: 0;
		background: linear-gradient(90deg, getColor('lightGray') 10%, rgba(getColor('lightGray'), 0));
	}

	&::after {
		right: 0;
		background: linear-gradient(90deg, rgba(getColor('lightGray'), 0), getColor('lightGray') 90%);
	}
}

.c-fadeScrollable__track {
	overflow-x: auto;
	white-space: nowrap;
}

.c-fadeScrollable--fadeStart::before {
	opacity: 1;
}

.c-fadeScrollable--fadeEnd::after {
	opacity: 1;
}
/*! purgecss end ignore */
