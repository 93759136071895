.c-reveal__content--box {
	border-color: getColor("gray20");
}

.c-reveal__content--box.is-active {
	border: 1px solid getColor("gray20");
}

.c-returningCustomerForm .c-reveal {
	overflow: visible;
}