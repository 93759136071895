/* ==============================================================================================
 * FORM
 * ============================================================================================== */

.c-form--border {
	border: 1px solid;
}

.c-form__breadcrumbHeaderLogin {
	position: relative;
}

.c-form__breadcrumbHeaderLogin:before {
	@include mq($until: "tabletH") {
		content: '';
		border-top: 1px solid getColor("gray90");
		display: block;
		position: absolute;
		left: 0;
		right: 0;
		font-size: getFontSize(12);
		margin-top: -1em;
	}
}
