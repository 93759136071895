/* =========================================================================
 * SPACING SETINGS
 * ========================================================================= */

$spacingFactors: map_merge($globalSpacingFactors, (
	"nxxs": -0.25,
	"nxs": -0.5,
	"nsm": -0.75,
	"n": -1,
	"nlg": -1.5,
	"nxl": -2,
	"nxxl": -4
));

$positionFactors: $spacingFactors;
