/* =====================================================================
 * HEADINGS
 * ===================================================================== */

h1, h2, h3, h4, h5, h6 {
	font-weight: 400;
	line-height: 1.25;
}

h1, h2, h3, h6 {
	font-weight: 600;
}

h4, h5 {
	line-height: 1.5;
}

h1 {
	font-size: 64px;
}

h2 {
	font-size: 48px;
}

h3 {
	font-size: 36px;
}

h4 {
	font-size: 24px;
}

h5 {
	font-size: 20px;
}

h6 {
	font-size: 16px;
}
