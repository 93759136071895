/* =========================================================================
 * BREAKPOINTS SETTINGS
 * ========================================================================= */

$mq-breakpoints: (
	tabletV: 600px,
	tabletH: 900px,
	laptop: 1200px,
	desktop: 1600px
);
