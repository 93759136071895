/* =========================================================================
 * BASE PROJECT SETTINGS
 * ========================================================================= */

// Enable markup fix because we use the spaceless tag in twig
$enableLayoutMarkupFix: false;

// Enable responsive layout
$enableResponsiveLayout: true;



/* Max Widths
--------------------------- */

$outerWrapperMaxWidth: null;
$wrapperMaxWidth: 1404px;

// Enable grid offset classes
$widthEnableOffsets: true;
