/* ==============================================================================================
 * CALL TO ACTION COMPONENT
 * ============================================================================================== */

.c-cta {
	display: inline-flex;
	align-items: center;
	margin: 0;
	padding: 0;
	background: none;
	font-family: $baseFontFamily;
	font-size: 14px; // [1]
	font-weight: 600;
	color: getColor("gray80");
	line-height: getLineHeight("title");
	vertical-align: middle;
}

.c-cta--button {
	appearance: none;
}

.c-cta:hover,
.c-cta:focus {
	color: getColor("red");
}

.c-cta__iconContainer {
	display: inline-block;
	position: relative;
	width: (20 / 14) * 1em; // 20px on default font size [1]
	height: (20 / 14) * 1em; // 20px on default font size [1]
	background: getColor("red");
	margin-right: (8 / 14) * 1em;
}

.c-cta:hover .c-cta__iconContainer,
.c-cta:focus .c-cta__iconContainer {
	color: getColor("darkRed");
}

.c-cta__icon {
	position: absolute;
	top: 50%;
	left: 50%;
	font-size: .572em; // 8px on default font size [1]
	color: #fff;
	transform: translate3d(-50%, -50%, 0);
}




/* Plain
 * --------------------------- */

.c-cta--plain .c-cta__icon {
	color: getColor("red");
}

.c-cta--plain .c-cta__iconContainer {
	width: auto;
	background: transparent;
	color: getColor("red");
}