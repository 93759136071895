.c-searchBar__input {
	width: 100%;
	max-width: 0;
	padding: $globalSpacingXs;

	transform: translateY(-50%);
	top: 50%;
	right: 0;

	opacity: 0;
	color: getColor('gray90');
	background-color: white;
	border: 1px solid getColor('gray20');
	transition: border-color .2s ease-in-out, max-width .4s ease-in-out, opacity .2s ease-in-out;

	&:focus {
		border-color: getColor('gray90');
	}

	&::placeholder {
		text-transform: uppercase;
		color: getColor('gray60');
	}

	&.is-active {
		opacity: 1;
		max-width: 640px;
	}
}

.c-searchBar__trigger {
	transition: transform .2s ease-in-out;

	&.is-active {
		transform: translateX(-16px);
	}
}

.c-searchBar--fixed {
	position: relative;
}

.c-searchBar--fixed .c-searchBar__input {
	max-width: 100%;
	opacity: 1;
	transform: none;

	&::placeholder {
		font-size: getFontSize(12);
		text-transform: uppercase;
		color: getColor('gray90');
	}
}

.c-searchBar--fixed .c-searchBar__icon {
	top: $globalSpacingXs;
	right: $globalSpacingXs;
}
