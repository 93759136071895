/* =========================================================================
 * LINK ELEMENTS
 * ========================================================================= */

a {
	cursor: pointer;
	color: inherit;
	text-decoration: none;

	.isActive {
		color: getColor("lightRed")
	}
}

