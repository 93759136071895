/* =========================================================================
 * TYPOGRAPHY SETTINGS
 * ========================================================================= */

$baseColor: getColor("gray90");

$baseFontFamily: "Akkurat Pro", Arial, sans-serif;
$headingsFontFamily: "Akkurat Pro", Arial, sans-serif;
$monospaceFontFamily: "Akkurat Mono", monospace, serif;

$fontSizes: (
	96: 96px,
	64: 64px,
	48: 48px,
	36: 36px,
	32: 32px,
	28: 28px,
	24: 24px,
	20: 20px,
	18: 18px,
	16: 16px,
	14: 14px,
	12: 12px,
	10: 10px,
);

$measureWidths: (
	"narrow": 20em,
	null: 30em,
	"wide": 34em,
	38: 38em,
);

$letterSpacings: (
	"tight": -0.5px,
	null: normal,
	"loose": 2px,
);

$lineHeights: (
	"solid": 1,
	"title": 1.25,
	"copy": $baseLineHeight,
	"lg": 2,
	"xl": 2.5,
);
