/* ==============================================================================================
 * FILE UPLOAD
 * ============================================================================================== */

.c-fileUpload__dropArea {
	position: relative;
	display: none;
}

.c-fileUpload__dropAreaMask {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	cursor: pointer;
}

.c-fileUpload__input,
.c-fileUpload__inputPlaceHolder {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	opacity: 0;
	visibility: hidden;
}

.c-fileUpload {
	position: relative;
	border: 4px dashed #D1D3D4;
	transition: border-color 250ms ease-in-out;
}

.c-fileUpload.highlight {
	border-color: #9a9a9a;
}

.c-fileUpload__dropArea {
	position: relative;
	display: flex;
	justify-content: center;
	background-color: #fff;
}

.c-fileUpload__dropArea,
.c-fileUpload__fileListWrapper {
	min-height: 350px;
}

.c-fileUpload__contentWrapper {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	max-width: 500px;
	padding: 64px 24px;
	text-align: center;
}

.c-fileUpload__title {
	margin-bottom: 16px;
	font-size: 20px;
}

.c-fileUpload__text {
	font-size: 16px;
}

.c-fileUpload__fileListWrapper {
	width: 100%;
	padding: 24px 32px;
}

.c-fileUpload__fileList {
	margin: 0;
	padding: 0;
	list-style: none;
}

.c-fileUpload__fileListItem {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 16px 0;
	border-bottom: 1px solid #D1D3D4;
	line-height: 1;
}

.c-fileUpload__fileListItem:last-child {
	border-bottom: 0;
}

.c-fileUpload__remove {
	padding: 0;
}

.c-fileUpload__fileName {
	flex-grow: 1;
}

.c-fileUpload.has-files .c-fileUpload__dropArea {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 10;
	width: 100%;
	height: 100%;
	opacity: 0;
	visibility: hidden;
	transition: opacity 250ms ease-in-out;
}

.c-fileUpload__fileListWrapper {
	display: none;
}

.c-fileUpload.has-files .c-fileUpload__fileListWrapper {
	display: block;
}

.c-fileUpload.has-files.highlight .c-fileUpload__dropArea {
	visibility: visible;
	opacity: 1;
}
