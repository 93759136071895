/* =========================================================================
 * BACKGROUND COLOR UTILITIES
 * ========================================================================= */

@mixin backgroundUtility($breakpoint: null) {
	._bgn#{$breakpoint} {
		background: none !important;
	}

	@each $colorName, $colorHex in $colors {
		._bg#{$colorName}#{$breakpoint} {
			background-color: $colorHex !important;
		}

		._hover\:bg#{$colorName}#{$breakpoint}:hover {
			background-color: $colorHex !important;
		}
	}
}

@include backgroundUtility();
