/* ==============================================================================================
 * CONTENT SWITCH
 * ============================================================================================== */

.o-contentSwitch {
	display: block;
	position: relative;
}

.o-contentSwitch__link {
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-size: 20px;
	padding: $globalSpacing 0;

	@include mq($from: "laptop") {
		font-size: 22px;
	}
}

.o-contentSwitch__icon {
	width: 20px;
	margin-left: $globalSpacingSm;
}

.o-contentSwitch__icon--rotate {
	transition: transform .2s ease-in-out;
}



/* Active State
 * --------------------------- */

.o-contentSwitch.is-active {
	font-weight: 600;
}

.o-contentSwitch.is-active .o-contentSwitch__icon--rotate {
	transform: rotate(-180deg);
}

.o-contentSwitch:hover,
.o-contentSwitch:focus {
	color: getColor("red");
}




/* Alternate
 * ============================================================================================== */

.o-contentSwitch--alt:after {
	position: absolute;
	display: block;
	content: '';
	background-color: getColor("red");
	height: 6px;
	bottom: 0;
	left: 0;
	transform-origin: left top;
	transform: scaleX(0);
	transition: transform ease-in-out .8s, color .4s ease-in-out;
	width: 100%;
}

.o-contentSwitch--alt.is-active {
	color: getColor("red");
}

.o-contentSwitch--alt.is-active:after {
	transform: scaleX(0.8);
}

.o-contentSwitch--alt .o-contentSwitch__link {
	display: block;
	padding: 30px 0;
	border-bottom: 3px solid getColor("gray20");
}
