/* ==============================================================================================
 * LETTER SPACING UTILITIES
 * ============================================================================================== */

._lsmd {
	letter-spacing: .03em !important;
}

._lslg {
	letter-spacing: .05em !important;
}

._lsxl {
	letter-spacing: .08em !important;
}
