/* ==========================================================================
 * MARGINS
 *
 * Shared declarations for certain elements.
 *
 * Always declare margins in the same direction:
 * csswizardry.com/2012/06/single-direction-margin-declarations
 * ========================================================================== */

/**
 * Consistent indentation for lists.
 */
dd, ol, ul {
	margin-left: $globalSpacing;
}
