/* ==============================================================================================
 * ICON MASK TOOL
 * ============================================================================================== */

/**
 * Returns the necessary rules to create an element that displays an icon
 *
 * Generally this will be used in pseudo-elements, but content is intentionally left out to make
 * this compatible with any element in the page.
 */
@mixin iconMask($path, $size: contain) {
	display: inline-block;

	width: 1em;
	height: 1em;

	mask: url($path) no-repeat 50% 50%;
	mask-size: $size;

	// autoprefixer doesn't pick up these, not sure why
	-webkit-mask: url($path) no-repeat 50% 50%;
	-webkit-mask-size: $size;
}
