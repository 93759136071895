
/* ==============================================================================================
 * DATE PICKER STYLE OVERRIDES
 * ============================================================================================== */

html .the-datepicker__container {
	left: 0;
}

html .the-datepicker__container .the-datepicker__main {
	min-width: 275px;
	border-radius: 0;
	font-family: inherit;
}

html .the-datepicker__main table.the-datepicker__calendar {
	width: 100%;
}

html .the-datepicker__main table.the-datepicker__calendar tr td,
html .the-datepicker__main table.the-datepicker__calendar tr th {
	text-align: center;
}

html .the-datepicker__deselect {
	top: 10px;
	right: 30px;
}

html .the-datepicker__deselect-button {
	color: getColor("red");
	font-weight: normal;
}

.ie .the-datepicker__deselect {
	top: 13px;
}

html .the-datepicker__main th.the-datepicker__week-day {
	font-weight: 700;
	cursor: default;
}

html .the-datepicker__main a.the-datepicker__button {
	display: inline-block;
	color: getColor("gray60");
}

html .the-datepicker__main td.the-datepicker__day a.the-datepicker__button .the-datepicker__day-content {
	font-size: 11px;
}

html .the-datepicker__main td.the-datepicker__day--weekend {
	opacity: .6;
}

html .the-datepicker__main td.the-datepicker__day a.the-datepicker__button:hover .the-datepicker__day-content,
html .the-datepicker__main a.the-datepicker__button:hover {
	background-color: #e8eae8;
}

html .the-datepicker__main .the-datepicker__month {
	padding-right: 10px;
}

html .the-datepicker__main select.the-datepicker__select {
	-moz-appearance: none;
	-webkit-appearance: none;
	width: 100%;
	padding: 6px;
	border: 1px solid getColor("gray40");
	background-image: url("/images/chevron-down--gray60.svg");
	background-size: 10px;
	background-repeat: no-repeat;
	background-position-x: calc(100% - 8px);
	background-position-y: 50%;
}

.ie .the-datepicker__main select.the-datepicker__select {
	background-image: none;
}

html .the-datepicker__main select.the-datepicker__select::-ms-expand {
	border: 0;
	background-color: inherit;
	color: getColor("gray40");
}

html .the-datepicker__main td.the-datepicker__day--selected a.the-datepicker__button .the-datepicker__day-content,
html .the-datepicker__main td.the-datepicker__day--selected.the-datepicker__day--highlighted a.the-datepicker__button .the-datepicker__day-content,
html .the-datepicker__main td.the-datepicker__day--selected a.the-datepicker__button:hover .the-datepicker__day-content,
html .the-datepicker__main td.the-datepicker__day--selected.the-datepicker__day--highlighted a.the-datepicker__button:hover .the-datepicker__day-content {
	background-color: getColor("red");
	font-weight: 700;
}


/* Date picker with hidden year selector
 * --------------------------- */

.c-input--noYearDate .the-datepicker__main .the-datepicker__year {
	display: none;
}

.c-input--noYearDate .the-datepicker__main .the-datepicker__month {
	width: 100%;
	padding-right: 0;
}