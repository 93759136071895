/* =========================================================================
 * STYLED LIST OBJECT
 *
 * This component assumes Wordpress is being used, hence the
 * high-specificity selectors
 * ========================================================================= */

/* Mixins
 * --------------------------- */

%styledList__item {
	list-style-type: none;
	padding-left: 1em;
}

%styledList__itemArrow {
	// @extend %materialIcons;
	content: "keyboard_arrow_right";
	position: absolute;
	margin-left: -1.5em;
	line-height: inherit;
	color: getColor("primary");
	text-transform: none;
}

%styledList__itemBullet {
	content: "•";
	position: absolute;
	margin-left: -1.3em;
	line-height: inherit;
	color: getColor("textGray");
	font-size: getFontSize(14);
}


/* Classes
 * --------------------------- */

.o-styledList__item {
	@extend %styledList__item;
}

.o-styledList__item + .o-styledList__item {
	margin-top: $globalSpacingXs;
}

.o-styledList__item:before {
	@extend %styledList__itemBullet;
}

.o-styledList--bullet > .o-styledList__item:before {
	@extend %styledList__itemBullet;
}

.o-styledList--mono .o-styledList__item:before {
	color: inherit;
}
