/* ==============================================================================================
 * CAROUSEL
 * ============================================================================================== */

.c-carousel {
	overflow: hidden;
	
	@supports not (-ms-high-contrast: none) {
		@include mq($from: "tabletH") {
			display: flex;
			justify-content: space-between;
			flex-direction: column;
			min-height: calc(100vh - #{$headerDesktopHeight + $globalSpacingLg});
		}

		@include mq($from: "laptop") {
			min-height: calc(100vh - #{$headerDesktopHeight + $globalSpacingXl});
		}
	}
}

.c-carousel__itemsContainer {
	flex: 1;
}

.c-carousel__itemTitle {
	animation-delay: 0.25s;
}

.c-carousel__imageContainer,
.c-carousel__itemDescription {
	animation-delay: 0.5s;
}

.c-carousel__itemCTA {
	animation-delay: 0.75s;
}

.c-carousel__imageContainer {
	position: relative;
	min-height: 300px;
	height: 100%;
}

.c-carousel__controllerItem {
	position: relative;
	cursor: pointer;
	height: 58px;
	color: getColor("gray90");
	font-size: getFontSize(20);
	border-bottom: 3px solid getColor("gray20");
}

.c-carousel__controllerItem:hover,
.c-carousel__controllerItem:focus,
.c-carousel__controllerItem.is-active {
	font-weight: 600;
}

.c-carousel__controllerItem:before {
	position: absolute;
	content: "";
	bottom: -3px;
	left: 0;
	height: 5px;
	background: getColor("red");
	transform-origin: left;
	width: 100%;
	transform: scaleX(0);
	transition: transform .5s;
}

.c-carousel__controllerItem.is-active:before {
	transform: scaleX(1);
	transition: transform 5s $easeOutQuad;
}

.c-carousel__controllerItem.is-previous:before {
	transform-origin: right;
}

.c-carousel__buttonIcon {
	@include mq($until: "tabletV") {
		float: right;
		margin-top: 0.25em;
	}
}

.c-carousel__item.swiper-slide-active .c-carousel__itemTitle,
.c-carousel__item.swiper-slide-active .c-carousel__itemDescription,
.c-carousel__item.swiper-slide-active .c-carousel__itemCTA,
.c-carousel__item.swiper-slide-active .c-carousel__imageContainer {
	animation-name: fadeInRight;
	animation-fill-mode: backwards;
	animation-duration: .8s;
}

@keyframes fadeInRight {
	0% {
		opacity: 0;
		transform: translateX(20px);
	}

	100% {
		opacity: 1;
		transform: translateX(0);
	}
}
