/* ==============================================================================================
 * LISTLINK
 *
 * Component that creates a simple box of links, using only page resources
 * ============================================================================================== */

.c-listLink__item {
	border-bottom: 2px solid getColor("lightGray");
}

.c-listLink__item:last-child {
	border: 0;
}

.c-listLink__icon {
	float: right;
}

.c-listLink__icon--circle {
	width: 1.5em;
	text-align: center;
	line-height: 1.5em;
	border-radius: 50%;
	display: inline-block;
	vertical-align: middle;
	font-size: 0.75em;
	margin: 0.25em 0;
}

.c-listLink__icon--circle .o-icon__img {
	height: 0.75em;
}
