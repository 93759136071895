/* =========================================================================
 * WRAPPER OBJECT MEDIA QUERIES
 * ========================================================================= */

$customWrapperSpacing: (
	null: $globalSpacingSm,
	"tabletV": $globalSpacing,
	"tabletH": $globalSpacing,
	"laptop": $globalSpacingXl,
);

@mixin customWrapperSpacing($breakpoint: null, $breakpointName: null) {
	$spacing: map-get($customWrapperSpacing, $breakpointName);

	@if ($spacing != null) {
		.o-wrapper {
			padding-right: $spacing;
			padding-left: $spacing;
			max-width: $wrapperMaxWidth + ($spacing * 2);
		}

		.o-wrapper.o-wrapper--flush {
			max-width: none;
		}
	}
}

@include customWrapperSpacing();
