/* ==============================================================================================
 * SUSHI BAZOOKA'S TOOLTIP OVERRIDES
 * ============================================================================================== */

.c-tooltip {
	visibility: visible;
	background: none;
	width: 340px;
	transform: translateX(-50%);
	bottom: auto;
	border-radius: 0;
	padding: 0;
}

.c-tooltip,
.c-tooltip__content,
.c-tooltip__arrow:before {
	box-shadow: none;
}

.c-tooltip__content {
	white-space: normal;
	text-align: left;
	font-size: 14px;
	line-height: 1.5;
	padding: 20px 26px;
	border-radius: 0;
}

.c-tooltip__content,
.c-tooltip__arrow:before,
.c-tooltip__arrow:after {
	background: getColor("gray90");
}

.c-tooltip--right .c-tooltip__arrow {
	right: calc(100% + 5px);
}
