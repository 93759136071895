/* ==============================================================================================
 * FOOTER
 * ============================================================================================== */

.c-footer__border {
	border-bottom: 1px solid getColor("gray80");
	height: 1px;
	width: 100%;
}

.c-footer__logo {
	width: 100%;
	max-width: 320px;
}
