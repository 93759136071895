/* ==============================================================================================
 * LISTS
 *
 * List Bullet - It's a list with adding bullet on left.
 * ============================================================================================== */

.c-list {
	counter-reset: list;
}

.c-list--number li::before,
.c-list--number .c-list__item::before,
.c-list--bullet li::before,
.c-list--bullet .c-list__item::before {
	position: absolute;
	left: 0;
	width: 1.5em;
	text-align: center;
}

.c-list--bullet li::before,
.c-list--bullet .c-list__item::before {
	content: '\2022';
}

.c-list--number li::before,
.c-list--number .c-list__item::before {
	counter-increment: list;
	content: counters(list, ".") ".";
}

.c-list--text li,
.c-list--text .c-list__item,
.c-list--number li,
.c-list--number .c-list__item,
.c-list--bullet li,
.c-list--bullet .c-list__item {
	line-height: 1.5em;
	padding-left: 1.5em;
	position: relative;
}

.c-list--text li,
.c-list--text .c-list__item {
	padding-left: 0;
}

.c-list--text li + li,
.c-list--text .c-list__item + .c-list__item,
.c-list--number li + li,
.c-list--number .c-list__item + .c-list__item,
.c-list--bullet li + li,
.c-list--bullet .c-list__item + .c-list__item {
	margin-top: 0.75em;
}

/*
 * List full height - adapts the border to fit other columns content
 * ============================================================================================== */

.c-list--fullHeight {
	flex-flow: row wrap;
}


/*
 * List Item border-top - That list item add border-top on item
 * ============================================================================================== */

.c-list__item--borderTop {
	border-top: 1px solid getColor("gray");
}

/*
 * List redBar - Adding red bar on right element
 * ============================================================================================== */

.c-list__redBar {
	border-right: 5px solid getColor("red");
	height: 100%;
}

.c-list__redBar--longer {
	height: calc(100% - #{$globalSpacing});
}

@include mq($from: "tabletV") {
	.c-list__item--borderLeft {
		border-left: 1px solid getColor("gray");
	}
}
