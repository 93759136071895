.c-searchResult {
    border: 1px solid getColor('lightGray');
    transition: border 0.3s ease;
}

.c-searchResult__title {
    transition: color 0.3s ease;
}

.c-searchResult__breadcrumbs {
    color: getColor('gray60');
}

.c-searchResult:hover {
    border-color: getColor('gray60');
}

.c-searchResult:hover .c-searchResult__title {
    color: getColor('red');
}

.search-highlighted {
    padding-top: 4px;
    padding-bottom: 2px;
    background-color: getColor('lightOrange');
    color: getColor('gray90');
}