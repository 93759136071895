._lst-disc {
	list-style-type: disc !important;
}

._lst-decimal {
	list-style-type: decimal !important;
}

._lst-plain {
	list-style-type: none !important;
	margin-left: 0;
}
