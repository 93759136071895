/* ==============================================================================================
 * REPEATER COMPONENT
 * ============================================================================================== */

/* Main Container
 * --------------------------- */

.c-repeater {
	position: relative;
}



/* Items List
 * --------------------------- */

.c-repeater__list {
	list-style-type: none;
	margin: 0;
	padding: 0;
	counter-reset: repeater 0;
}



/* Borders
 * --------------------------- */

.c-repeater,
.c-repeater__item + .c-repeater__item {
	border-top: 1px solid getColor("gray20");
}

.c-repeater {
	border-bottom: 1px solid getColor("gray20");
}


.c-repeater__item {
	counter-increment: repeater;
	display: flex;
	position: relative;
}



/* Item counter
 * --------------------------- */

.c-repeater__item:before {
	content: counter(repeater);
	display: flex;
	align-items: center;
	padding: 4px;
	font-size: 10px;
	font-weight: 700;
	color: getColor("gray40");
	background: getColor("gray10");
}



/* Content
 * --------------------------- */

.c-repeater__itemContent {
	flex: 1;
}



/* Add Row Button
 * --------------------------- */

.c-repeater__addRow {
	position: absolute;
	bottom: -10px;
	right: 24px;
	padding: 2px 6px;
	margin: 0;
	font-weight: 400;
	color: getColor("red");
	text-transform: uppercase;
	background: #fff;

	&:hover,
	&:focus {
		color: getColor("darkRed");
	}
}



/* Remove single item button
 * --------------------------- */

.c-repeater__removeContainer {
	display: flex;
	align-items: center;
	background: getColor("gray10");
}

.c-repeater__remove {
	cursor: pointer;
	margin: 0 -1px;
	padding: 0;
	background: none;
	text-decoration: none;
	transition: 0.3s;
	white-space: nowrap;
	position: relative;
	z-index: 1;
	width: 15px;
	height: 15px;
	border-radius: 50%;
	background: #fff;
}

.c-repeater__remove:before {
	@include iconMask("/images/icons/remove-item.svg");

	content: "";
	width: 15px;
	height: 15px;
	font-size: 12px;
	background: getColor("gray20");
}

.c-repeater__remove:hover:before,
.c-repeater__remove:focus:before {
	background: getColor("red");
}

.c-repeater__item:first-child .c-repeater__remove {
	visibility: hidden;
}
