/* ==============================================================================================
 * TABBED OF CONTENTES COMPONENT
 * ============================================================================================== */

.c-toc .o-icon {
	transition-property: transform;
	transition-duration: $revealTransitionDuration;
	transition-timing-function: $revealTransitionTimingFunction;
}

.c-toc__section .c-toc__link.is-active .o-icon{
	transform: rotateZ(180deg);
}

.c-toc__section--level1 > .c-toc__link.is-active span,
.c-toc__section.is-active .c-toc__link {
	color: getColor("red");
}
