/* ==============================================================================================
 * TIMELINE
 * ============================================================================================== */

.c-timeline__button {
	border: 1px solid getColor("gray60");
	border-radius: 50%;
	width: 46px;
	height: 46px;
	padding: 0;
	flex-shrink: 0;
}

.c-timeline__line {
	$color: getColor("gray60");
	flex-grow: 1;
	position: relative;
	border-image-slice: 1;
	border-bottom: 2px solid;
	border-image-source: linear-gradient(90deg, transparent 0%, $color 5%, $color 95%, transparent 100%);
}

.c-timeline__datesListWrapper {
	position: absolute;
	bottom: 0;
	left: 46px; // timeline button width 
	height: 70px;
	overflow-x: hidden;
	overflow-y: visible;
	margin: 0;
}

.c-timeline__datesList {
	display: flex;
	transition: transform .6s ease-in-out;
}

.c-timeline__date {
	position: absolute;
	display: flex;
	flex-direction: column;
	align-items: center;
	cursor: pointer;
	color: getColor("gray60");
	transform: translateX(-50%);
	transition: color .6s ease-in-out;

	&.is-active {
		color: getColor("lightGray");

		.c-timeline__dot {
			background-color: getColor("lightGray");
		}
	}
}

.c-timeline__dot {
	border-radius: 50%;
	width: 23px;
	height: 23px;
	background-color: getColor("gray60");
	border: 3px solid getColor("gray90");
	transition: background-color .6s ease-in-out;
}

.c-timeline__border {
	border-bottom: 1px solid getColor("black");

	@include mq($from: "laptop") {
		border-color: getColor("white");
	}
}
