/* =========================================================================
 * PAGE
 * ========================================================================= */

html {
	font-family: $baseFontFamily;
	color: $baseColor;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

body {
	font-size: getFontSize(16);
}
