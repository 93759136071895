/* ==============================================================================================
 * NEWSLETTER
 * ============================================================================================== */

.c-newsletterSignUp__toggle {
	background: getColor("gray90");
}

.c-newsletterSignUp__toggle.is-active,
.c-newsletterSignUp__toggle:hover,
.c-newsletterSignUp__toggle:focus {
	background: #000;
}

.c-newsletterSignUp__toggle:focus {
	outline: none;
}

.c-newsletterSignUp__iconContainer {
	flex-shrink: 0;
}

.c-newsletterSignUp__openIcon,
.c-newsletterSignUp__toggle.is-active .c-newsletterSignUp__closedIcon {
	display: none;
}

.c-newsletterSignUp__closedIcon,
.c-newsletterSignUp__toggle.is-active .c-newsletterSignUp__openIcon {
	display: block;
}

.c-newsletterSignUp__openIcon {
	// this aligns the open envelope with the closed envelope
	transform: translateY(-8px);
}

.c-newsletterSignUp__formContainer {
	position: relative;
}

.c-newsletterSignUp__formContainer:before {
	content: "";
	position: absolute;
	top: 0;
	left: 50%;
	width: 16px;
	height: 16px;
	background: getColor("white");
	transform: translate3d(-50%, 20%, 0) rotateZ(45deg);
	transition: .6s $easeOutSine;
}

.c-reveal.is-active .c-newsletterSignUp__formContainer:before {
	transform: translate3d(-50%, -50%, 0) rotateZ(45deg);
}


/*  FIX MARKETO FORM STYLING FOR CHECKBOX FIELD
 * ============================================================================================== */
.c-newsletterSignUp__formContainer .mktoCheckboxList {
	display: flex;
	align-items: flex-start;
	width: 100% !important;
}

.c-newsletterSignUp__formContainer .mktoCheckboxList label {
	flex-grow: 1;
}

.c-newsletterSignUp__formContainer .mktoCheckboxList .c-input__field {
	margin-top: $globalSpacingXxs * .8;
	margin-right: $globalSpacingXxs;
}

.c-newsletterSignUp__formContainer .mktoHtmlText > div {
	text-align: left !important;
}

.ie .c-newsletterSignUp__formContainer .mktoCheckboxList .c-input__field {
	margin-top: $globalSpacingXxs * .5;
}