/* ==============================================================================================
 * TABLE
 * ============================================================================================== */

.c-table {
	width: 100%;
	color: getColor("gray60");
	text-align: left;
}

.c-table__head {
	font-weight: 700;
	border-top: 2px solid getColor("gray20");
	border-bottom: 2px solid getColor("gray20");
}

.c-table__body {
	border-bottom: 2px solid getColor("gray20");
	vertical-align: top;
}

.c-table__cell {
	padding: $globalSpacingXs;
}

.c-table__cell:first-child {
	padding-left: 0;
}

.c-table__cell:last-child {
	padding-right: 0;
}




/* ==============================================================================================
 * OUTLINED TABLE
 * ============================================================================================== */

.c-table--outline {
	width: 100%;
	text-align: left;
	border: 1px solid getColor('gray20');
}

.c-table--outline .c-table__cell {
	border-bottom: 1px solid getColor("gray20");
	padding: $globalSpacingXxs $globalSpacingXs;
	font-size: getFontSize(12);
	line-height: 150%;

	@media (min-width: map_get($mq-breakpoints, 'tabletH')) {
		font-size: getFontSize(14);
		padding: $globalSpacingXs;
	}
}

.c-table--outline .c-table__cell[data-sortable] {
	cursor: pointer;

	span {
		position: relative;
		display: flex;
		align-items: center;

		&::before {
			margin-right: .5em;
			width: 6px;
			height: 10px;
			background-repeat: no-repeat;
		}
	}
}

.c-table--outline .c-table__cell--sortedAsc[data-sortable] span::before {
	background-image: url("/images/icons/arrow-up-gray.svg");
	content: '';
}

.c-table--outline .c-table__cell--sortedDesc[data-sortable] span::before {
	background-image: url("/images/icons/arrow-down-gray.svg");
	content: '';
}

.c-table--outline .c-table__cell--link,
.c-table--outline .c-table__cell[data-action] {
	color: getColor("red");
	cursor: pointer;
}

.c-table--outline .c-table__head {
	font-weight: 700;
	text-transform: uppercase;
	color: getColor('gray40');
	border-top: 0;
	border-bottom: 1px solid getColor("gray20");
}

.c-table--outline .c-table__head .c-table__cell {
	border-right: 1px solid getColor("gray20");
	font-size: getFontSize(10);
	line-height: 125%;
	letter-spacing: 0.08em;

	@media (min-width: map_get($mq-breakpoints, 'tabletH')) {
		font-size: getFontSize(12);
	}
}

.c-table--outline .c-table__body {
	border-bottom: 0;
	vertical-align: top;
}

.c-table--outline .c-input__checkbox {
	width: 12px;
	height: 12px;

	&:before {
		width: 12px;
		height: 12px;
	}

	&:after {
		width: 6px;
		height: 6px;
	}
}




/* ==============================================================================================
 * COMPACT TABLE
 * ============================================================================================== */

.c-table--compact .c-table__head .c-table__cell {
	padding: $globalSpacingXxs;
	font-size: getFontSize(10);
}

.c-table--compact .c-table__body .c-table__cell--checkbox {
	padding: $globalSpacingXs $globalSpacingXxs;
}


/* ==============================================================================================
 * TABLE DETAILS
 * ============================================================================================== */

.c-table__row.c-table__row--active + .c-table__detailRow {
	display: table-row;
}

.c-table__detailRow {
	display: none;
}