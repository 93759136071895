/* ==============================================================================================
 * CARD
 * ============================================================================================== */

.c-card {
	background-color: getColor("gray90");
	height: 100%;
}

.c-card__ctaWrapper {
	margin-top: auto;
}

.c-card__cta {
	border: 0;
	text-transform: uppercase;
	font-size: getFontSize(12);
}
