hr {
	width: 100%;
	border-top: 1px solid getColor("gray20");
	border-right: none;
	border-bottom: none;
	border-left: none;
	flex-grow: 1;
	margin: 0;
}

.c-horizontalRule--aboveBg {
	border-color: getColor("gray80");

	@include mq($from: "tabletH") {
		border-color: getColor("white");
	}
}
