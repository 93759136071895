/* ==============================================================================================
 * CARD LIST
 * ============================================================================================== */

.c-cardList__list {
	display: flex;

	// rough width to fit the content into a single line
	// this container also uses negative left margin, so we take it into account here (32px)
	min-width: $wrapperMaxWidth + 32px;
}
