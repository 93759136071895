/* ==============================================================================================
 * ACCORDION COMPONENT
 * ============================================================================================== */

.c-accordion {
	border-top: 1px solid getColor("gray20");
}

.c-accordion__item {
	border-bottom: 1px solid getColor("gray20");
}
