/* ==============================================================================================
 * IDENTIFIER BREAKDOWN
 * ============================================================================================== */

.c-identifierBreakdown {
	overflow-x: auto;
	overflow-y: hidden;
}

.c-identifierBreakdown__content {
	// rough width to fit the content into a single line
	// this container also uses negative left margin, so we take it into account here (32px)
	min-width: 890px + 32px;
}
