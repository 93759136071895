/* ==============================================================================================
 * BUTTONS
 * ============================================================================================== */

.c-btn {
	background-color: getColor("red");
	display: inline-block;
	padding: 15px 20px;
	font-weight: 600;
	font-size: 0.75rem;
	cursor: pointer;
	font-family: $baseFontFamily;
	letter-spacing: 0.08em;
	transition: background-color 0.3s, color 0.3s;
}

.c-btn:hover {
	background-color: #000;
}


/* Button icon
 * --------------------------- */

.c-btn .o-icon {
	font-size: 0.85em;
}


/* Button Background white
 * --------------------------- */

.c-btn--white {
	background-color: getColor("white");
}

/* Button Background transparent
 * --------------------------- */

.c-btn--transparent {
	background-color: transparent;
}


/* Button Icon Only
 * --------------------------- */

.c-btn--iconOnly {
	position: relative;
	padding: 0;
	width: 20px;
	height: 20px;
	background-color: getColor("red");
	color: getColor("white");

	.c-btn__icon {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%) scale(0.8);
	}
}

.c-btn--iconOnly.c-btn--small {
	width: 20px;
	height: 20px;
	font-size: 10px;
}


/* Button Flat
 * --------------------------- */

.c-btn--flat {
	border: none;
	padding: 0;
	outline: none;
}


/* Button with border
 * --------------------------- */

.c-btn--border {
	border: 1px solid currentColor;
}


/* Button with outline
 * --------------------------- */

.c-btn--outline {
	border: 1px solid getColor("gray20");
}


/* Button Background red on hover
 * --------------------------- */

.c-btn--hoverRed:hover {
	color: #fff !important;
	background-color: getColor("red");
}

/* Button Background white on hover
 * --------------------------- */

 .c-btn--hoverWhite:hover {
	background-color: getColor("white");
}

/* Button Background transparent on hover
* --------------------------- */

.c-btn--hoverTransparent:hover {
	background-color: transparent;
}


/* Button disabled
 * --------------------------- */

.c-btn[disabled] {
	background-color: getColor("gray20");
	cursor: default;
}

.c-btn--white[disabled] {
	color: getColor("gray20") !important;
	background-color: getColor("white");
}