/* ==============================================================================================
 * HEADER
 * ============================================================================================== */

.c-header {
	border-bottom: 1px solid getColor("gray20");
	position: relative;
	z-index: 10;
	height: $headerMobileHeight;

	@include mq($from: "laptop") {
		height: $headerDesktopHeight;
		border-bottom: 0;
	}
}

.c-header__content {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.c-header__logo {
	width: 105px;
	height: 94px;
	flex-shrink: 0;

	@include mq($from: "tabletV") {
		margin-left: -26px;
	}

	@include mq($from: "laptop") {
		width: 155px;
		height: 129px;
		margin-left: -35px;
		margin-top: -8px;
	}
}

.ie .c-header__logo {
	background-image: url("/images/logo.svg");
	background-position: center center;
	background-size: contain;
	background-repeat: no-repeat;
}

.c-header__mobileMenuToggle {
	right: 0;
	z-index: 5;
}
