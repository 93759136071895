/* ==============================================================================================
 * FLOATING PLACEHOLDER
 * ============================================================================================== */

.c-floatingLabel {
	position: absolute;
	color: getColor("gray40");
	background-color: getColor("white");
	border-radius: 0.5em;
	z-index: 1;
	padding: 0 5px;
	top: 22px;
	left: 15px;
	transform: translateY(-50%);
	transition: 0.15s font-size, 0.15s top;
	pointer-events: none;
	text-transform: uppercase;
	font-size: 12px;
	letter-spacing: 0.08em;
}

.c-floatingLabel.is-floating {
	top: 0;
	font-size: 8px;
}
