.c-mobileMenu {
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	z-index: 10;
	transform: translateX(100%);
	transition: 0.2s transform ease-in;
	overflow: auto;
}

.c-mobileMenu.is-active {
	transform: translateX(0);
}

.c-mobileMenu__close {
	margin-top: 6px;
}
