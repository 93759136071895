/* ==============================================================================================
 * TABBED
 * ============================================================================================== */

.c-tabbed__tabs {
	padding-top: $globalSpacingSm;
}

.c-tabbed__tab {
	display: none;
}

.c-tabbed__tab.is-active {
	display: block;
}
