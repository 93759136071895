/* ==============================================================================================
 * VIDEO PLAYER
 * ============================================================================================== */

.c-videoPlayer .plyr {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}
