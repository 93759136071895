/* ==============================================================================================
 * PLYR STYLE OVERRIDES
 * ============================================================================================== */

.plyr__captions {
	font-size: 10px !important;
}

.plyr:-webkit-full-screen,
.plyr:fullscreen  {
	.plyr__captions {
		font-size: 16px !important;
	}
}

@media (min-width: 480px) {
	.plyr__captions {
		font-size: 12px !important;
	}

	.plyr:-webkit-full-screen,
	.plyr:fullscreen  {
		.plyr__captions {
			font-size: 18px !important;
		}
	}
}

@media (min-width: 768px) {
	.plyr__captions {
		font-size: 14px !important;
	}

	.plyr:-webkit-full-screen,
	.plyr:fullscreen  {
		.plyr__captions {
			font-size: 21px !important;
		}
	}
}

.plyr--video .plyr__control.plyr__tab-focus,
.plyr--video .plyr__control:hover,
.plyr--video .plyr__control[aria-expanded=true] {
	background: getColor("red") !important;
	box-shadow: none !important;
}

.plyr input[type=range] {
	color: getColor("red") !important;
}

.plyr__control--overlaid {
	display: none !important;
}
