/* ==============================================================================================
 * BARE BUTTON
 *
 * Use this class to remove the default appearance of HTML button tags
 * ============================================================================================== */

.o-bareBtn {
	display: block;
	appearance: none;
	border: 0;
	margin: 0;
	padding: 0;
	background: none;
	font-family: inherit;
	text-align: inherit;
	white-space: normal;
}
