/* ==============================================================================================
 * ICON OBJECT
 * ============================================================================================== */

.o-icon {
	display: inline-block;
	position: relative;
	height: 1em;
	width: 1em;
}

.o-icon__img {
	position: absolute;
	top: 0;
	left: 0;
	height: 1em;
	width: 1em;
	fill: transparent;
	color: inherit;
}

.o-icon__img path {
	color: inherit;
}

.o-icon__img path:not([fill]):not([stroke]),
.o-icon__img path[fill] {
	fill: currentColor;
}

.o-icon__img path[stroke] {
	stroke: currentColor;
}
