/* ==============================================================================================
 * MARKETO OVERRIDES
 * ============================================================================================== */

.mktoErrorMsg {
	position: absolute;
	left: 0;
	color: getColor("red");
	font-size: getFontSize(12);
}
