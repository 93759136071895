/* ==============================================================================================
 * DROPDOWN MENU
 * ============================================================================================== */

.c-dropdownMenu {
	position: relative;
}

.c-dropdownMenu::before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: -1em;
	cursor: pointer;
}




/* Link
 * ============================================================================================== */

.c-dropdownMenu__link {
	cursor: pointer;
	text-decoration: none;
	transition: 0.3s;
	white-space: nowrap;
	position: relative;
	z-index: 1;
}

.c-dropdownMenu__link::after {
	@include iconMask("/images/icons/chevron-down.svg");

	content: '';
	margin-left: 12px;
	font-size: 10px;
	cursor: pointer;
	transition: 0.3s transform;
	background: getColor('red');
}

.ie .c-dropdownMenu__link::after {
	background-image: url("/images/chevron-down--red.svg");
	background-color: transparent;
	background-position: center center;
	background-size: contain;
	background-repeat: no-repeat;
}

.c-dropdownMenu__link--currentColor::after {
	background: currentColor;
}




/* Menu
 * ============================================================================================== */

.c-dropdownMenu__menu {
	position: absolute;
	min-width: 120px;
	display: block;
	z-index: 10;
	left: 0;
	background: getColor("white");
	color: getColor("black");
	box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
	margin-top: 0.5em;

	height: 0;
	overflow: hidden;

	transition: 0.3s transform, 0.3s opacity;
	opacity: 0;
	transform: translate3d(0, -20%, 0);
}

.c-dropdownMenu__menu--alignRight {
	right: 0;
	left: auto;
}




/* Hover effects
 * ============================================================================================== */

.c-dropdownMenu:hover .c-dropdownMenu__menu {
	padding: 12px 18px;
	height: auto;
	opacity: 1;
	transform: translate3d(0, 0, 0);
}

.c-dropdownMenu:hover .c-dropdownMenu__link {
	color: getColor('red');
}

.c-dropdownMenu:hover .c-dropdownMenu__link::after {
	transform: rotate(-180deg);
}



/* Gray
 * ============================================================================================== */

.c-dropdownMenu--gray .c-dropdownMenu__link {
	color: getColor("gray60");
}

.c-dropdownMenu--gray .c-dropdownMenu__link::after {
	background-color: getColor('gray60');
}

.c-dropdownMenu--gray:hover .c-dropdownMenu__link {
	color: getColor('gray60');
}

.ie .c-dropdownMenu--gray .c-dropdownMenu__link::after {
	background-image: url("/images/chevron-down--gray60.svg");
	background-color: transparent;
}