.c-background--quarter {
	position: relative;
}

.c-background--quarter::before {
	content: '';
	background: getColor("lightGray");
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	height: 156px;
	z-index: -1;
}
